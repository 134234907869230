<template>
    <div class="form-list">
        <el-form ref="_importDataTemplate" :model="dataSource">
            <el-form-item>
                <el-col :span="3" class="form-title">
                    <span style="color:red;">*</span>模板选择：
                </el-col>
                <el-col :span="7">
                    <el-form-item>
                        <el-select v-model="upLoadData.TemplateID" placeholder="选择模板" class="select-import">
                            <el-option v-for="item in templateList" :key="item.Key" :label="item.Value"
                                :value="item.Key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    点击<el-button type="text" @click="DownloadFile()"
                        style="text-decoration:underline;color:#1874CD;cursor:pointer">这里</el-button>下载导入模板
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="3">
                    &nbsp;
                </el-col>
                <el-col :span="7">
                    <!--:beforeUpload="beforeUpload"-->
                    <el-upload class="upload-demo" :action="importAction" :on-preview="handlePreview"
                        :on-remove="handleRemove" :before-remove="beforeRemove" :data="upLoadData" multiple :limit="1"
                        :on-exceed="handleExceed" :beforeUpload="beforeUpload" :onSuccess="uploadSuccess"
                        :file-list="fileList" accept=".xls,.xlsx" :on-error="uploadError">
                        <el-button size="small" style="width: 80px;" type="primary" :loading="loading"
                            :disabled="loading">{{ updateText }}
                        </el-button>
                        <div slot="tip" class="el-upload__tip" style="overflow-y:auto;"><span v-text="tipmsg"
                                style="word-break:break-all"></span></div>
                    </el-upload>
                </el-col>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import sha from '../../../scripts/sha.js';
export default {
    data() {
        return {
            importAction: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?token=" + this.Utils.getToken(),
            //	importAction: window.AppConfig.apiCustomerUrl + "omsapi/product/importproduct?currentType=" + this.$store
            //	.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode,
            upLoadData: {
                TemplateID: '',
                isUpdateProduct: true,
                // updateType: 1,
                //cpyId: '123456',
                //occurTime: '2017-08'
            },
            tipmsg: "",
            templateList: [],
            fileList: [],
            loading: false,
            updateText: "导入"
        };
    },
    props: {
        dataSource: {
            businessType: 1,
            importAction: null,
        }
    },
    mounted() {
        this.loadTemplateListByBusinessType();
    },
    methods: {
        loadTemplateListByBusinessType(code) {
            this.$ajax.send("omsapi/template/getimporttemplate", "get", {
                businessType: this.dataSource.businessType
            }, (data) => {
                this.templateList = [];
                if (data.Result != null && data.Result != "") {
                    this.templateList = data.Result;
                }
            });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            if (file && file.status === "success")
                return this.$confirm(`确定移除 ${file.name}？`);
        },
        // 上传成功后的回调
        uploadSuccess(response, file, fileList) {
            this.uploadTHint(false);
            console.log(response);
            this.tipmsg = response.OperationDesc;
            this.UploadData = response.Result;
            //this.Utils.messageBox(response.OperationDesc, response.IsSuccess ? "success" : "error");
        },
        // 上传前对文件的大小的判断
        beforeUpload(file) {
            if (this.upLoadData.TemplateID == "" || this.upLoadData.TemplateID == null) {
                this.Utils.messageBox('模板不能空。', "error");
                return false;
            }
            this.uploadTHint(true);
            var extension = file.name.substring(file.name.lastIndexOf('.') + 1);
            var allowExtension = ['xls', 'xlsx'];
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (allowExtension.indexOf(extension.toLowerCase()) < 0) {
                this.Utils.messageBox('上传模板只能是 xls、xlsx格式!', "error");
                this.uploadTHint(false);
                return false;
            }
            if (!isLt2M) {
                this.Utils.messageBox('上传模板大小不能超过 10MB!', "error");
                this.uploadTHint(false);
                return false;
            }
            return extension && isLt2M;
        },
        // 上传错误
        uploadError(response, file, fileList) {
            this.uploadTHint(false);
            this.Utils.messageBox("上传失败，请重试！", "error");
        },
        DownloadFile: function () {
            if (this.upLoadData.TemplateID == null || this.upLoadData.TemplateID == '' || this.upLoadData
                .TemplateID == this.Utils.emptyGuid) {
                this.Utils.messageBox("请选择模板。", "error");
                return;
            }
            this.$ajax.send("omsapi/template/search", "get", {
                id: this.upLoadData.TemplateID
            }, (data) => {
                this.templateList = [];
                if (data.Result != null) {
                    this.Utils.exportUrl("omsapi/files/download?file="+data.Result.UploadFileName+"&url="+data.Result.ServerFileName + "&token=" + sha.getToken1());
                }
            });
        },
        uploadTHint(value) {
            if (value) {
                this.loading = true;
                this.updateText = "拼命上传中...";
            } else {
                this.loading = false;
                this.updateText = "导入";
            }
        },
        //保存数据
        saveFlie(options) {
            if (this.upLoadData.TemplateID != null && this.upLoadData.TemplateID != '') {
                this.upLoadData.CustomerCode = this.dataSource.CustomerCode;
                this.upLoadData.FileName = this.UploadData.FileName;
                this.upLoadData.FileUrl = this.UploadData.Url;
                this.$ajax.send(this.dataSource.importAction, "post", this.upLoadData, (
                    data) => {
                    this.upLoadData.OrderImportID = data.Result;
                });

                options.visible = false;
            } else {
                return this.Utils.messageBox("请先上传文件.", "error");;
            }
        },
    }
}
</script>
<style scope>
input[type="file"] {
    display: none;
}

.el-upload-list {
    width: 200px;
}

.el-select {
    width: 135px;
}
</style>